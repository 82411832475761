.heading {
  font-size: var(--font-md);
  font-weight: var(--font-weight-medium);
}

.subHeading {
  font-size: var(--font-xs);
}

.heading, .subHeading {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.flex {
  display: flex;
  align-items: center;
  gap: 8px;
}
