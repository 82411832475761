.pointer {
  cursor: pointer;
}

.image {
  border-radius: 8px;
  transition: transform 0.3s ease, border-radius 0.3s ease;
  max-width: 100%;
}

.image.fullscreen {
  position: fixed;
  border-radius: 0px !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: contain !important;
  background-color: black !important;
  z-index: 9999 !important;
  transform: scale(1);
}
